.app-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
}

.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-container input[type="text"] {
  width: 500px;
  padding: 8px;
  font-size: 16px;
}

.search-container button {
  padding: 8px 16px;
  font-size: 16px;
  margin-left: 10px;
}

.image-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.image-container {
  position: relative;
  overflow: auto; /* 允许滚动查看内容 */
  max-height: 100vh; /* 限制最大高度 */
}

.controls {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.controls button {
  margin-bottom: 5px;
  padding: 10px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s;
}

.controls button:hover {
  background-color: #f0f0f0;
}

.controls button:focus {
  outline: none;
}

/* 添加媒体查询以调整移动端的按钮位置 */
@media (max-width: 600px) {
  .controls {
    bottom: 20px; /* 在移动端上稍微向上移动 */
    right: 20px; /* 在移动端上稍微向左移动 */
  }
}

.image-wrapper {
  position: relative;
  display: inline-block;
  transition: transform 0.3s ease;
  cursor: grab; /* 显示抓手光标 */
}

.image-wrapper:active {
  cursor: grabbing; /* 拖动时显示抓取光标 */
}

.image-wrapper img {
  display: block;
  max-width: 100%;
  height: auto;
}

.annotation {
  position: absolute;
  width: 20px; /* 调整图标的宽度 */
  height: 20px; /* 调整图标的高度 */
  background-color: rgba(0, 123, 255, 0.8);
  border-radius: 50% 50% 50% 0; /* 创建定位图标的形状 */
  transform: translate(-50%, -50%) rotate(-45deg); /* 旋转图标 */
  pointer-events: none;
  animation: bounce 1.5s infinite; /* 添加弹跳动画 */
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
}

.annotation::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px; /* 调整中心圆点的宽度 */
  height: 9px; /* 调整中心圆点的高度 */
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.annotation::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px; /* 缩小外环的宽度 */
  height: 24px; /* 缩小外环的高度 */
  border: 2px solid rgba(0, 123, 255, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: ripple 1.5s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translate(-50%, -50%) rotate(-45deg) translateY(0);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-45deg) scale(1.2);
  }
}

@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.7;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}
